<script>
import { h, Transition } from 'vue';

export default {
  name: 'AutoHeightTransition',
  render(context) {
    const data = {
      name: 'autoheight-transition',
      class: 'autoheight-content',
      onAfterEnter(element) {
        element.style.height = 'auto';
      },
      onEnter(element) {
        let width;
        let height;
        try {
          width = getComputedStyle(element).width;
        } catch (_e) {
          return;
        }

        element.style.width = width;
        element.style.position = 'absolute';
        element.style.visibility = 'hidden';
        element.style.height = 'auto';

        try {
          height = getComputedStyle(element).height;
        } catch (_e) {
          return;
        }

        element.style.width = null;
        element.style.position = null;
        element.style.visibility = null;
        element.style.height = 0;

        try {
          // Force repaint to make sure the
          // animation is triggered correctly.
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          getComputedStyle(element).height;
        } catch (_e) {
          // error is okay
        }
        try {
          requestAnimationFrame(() => {
            element.style.height = height;
          });
        } catch (_e) {
          // error is okay
        }
      },
      onLeave(element) {
        let height;
        try {
          height = getComputedStyle(element).height;
        } catch (_e) {
          return;
        }

        element.style.height = height;
        try {
          // Force repaint to make sure the
          // animation is triggered correctly.
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          getComputedStyle(element).height;
        } catch (_e) {
          // error is okay
        }
        try {
          requestAnimationFrame(() => {
            element.style.height = 0;
          });
        } catch (_e) {
          // error is okay
        }
      },
    };

    try {
      return h(Transition, data, () => context.$slots.default());
    } catch (_e) {
      return null;
    }
  },
};
</script>

<style></style>
